<template>
  <div class="admin flexB">
    <Lnb />
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import Lnb from "@/components/Lnb";

export default {
  mixins: [format, setNavbar],
  components: { Lnb },
  mounted() {
    this.setNavbar(false);
  },
  computed: {
    ...mapState(["navVisible"]),
  },
  beforeDestroy() {
    this.setNavbar(true);
  },
};
</script>
