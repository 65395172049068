<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/admin/dashboard">
        <img src="@/assets/images/logo_wh.png" alt="logo" />
      </router-link>
    </div>

    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="0">
            <router-link to="/admin/dashboard">
              <span>
                <img src="@/assets/images/icon_svg/LNB_dashboard.svg" alt="icon" class="normal" />
                대시보드
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="1">
            <router-link to="/admin/users">
              <span>
                <img src="@/assets/images/icon_svg/LNB_group.svg" alt="icon" class="normal" />
                회원 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/admin/farms">
              <span>
                <img src="@/assets/images/icon_svg/LNB_foundation.svg" alt="icon" class="normal" />
                농장 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="6">
            <router-link to="/admin/growths">
              <span>
                <img src="@/assets/images/icon_svg/LNB_growth.svg" alt="icon" class="normal" />
                생육데이터 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/admin/devices">
              <span>
                <img src="@/assets/images/icon_svg/LNB_device.svg" alt="icon" class="normal" />
                디바이스 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item index="4">
            <router-link to="/admin/cultureInfos">
              <span>
                <img src="@/assets/images/icon_svg/LNB_crops.svg" alt="icon" class="normal" />
                작물재배정보
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item index="7">
            <router-link to="/admin/diarys">
              <span>
                <img src="@/assets/images/icon_svg/LNB_note.svg" alt="icon" class="normal" />
                영농일지 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="5">
            <router-link to="/admin/notices">
              <span>
                <img src="@/assets/images/icon_svg/LNB_magazine.svg" alt="icon" class="normal" />
                공지사항
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="9">
            <router-link to @click.native="logout">
              <span>
                <img src="@/assets/images/icon_svg/icn-logout.svg" alt="icon" class="normal" />
                로그아웃
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  methods: {
    logout() {
      this.$confirm("로그아웃 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        this.$store.dispatch("LOGOUT");
      });
    },
  },
};
</script>
